import React from 'react'

import Cart from '~/components/Cart'
import Footer from '../components/Footer'

const CartPage = () => (
  <>
    <Cart />
    <Footer />
  </>
)

export default CartPage
