import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import media from '../../styles/media'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import emailjs from 'emailjs-com'
import firebase from '../../utils/firebase'

import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import {
  HeadingText,
  Button,
  CartTable,
  CartSummary,
  BodyText,
  Divider,
  DetailsText,
  CrumbCon,
  FormInput,
} from '../../styles'
import { isMobile } from 'react-device-detect'
import Navigation from '../Navigation'

const CartCon = styled.div`
  display: block;
  margin: var(--gutter-s);
  .total-row {
    display: flex;
    justify-content: space-between;
  }
  .downloadLink {
    width: 200px;
    height: 50px;
    font-size: 14px;
    line-height: 22px;
    padding: 17px 44px;
    color: var(--white);
    border: none;
    background: var(--blue);
    text-align: center;
    letter-spacing: 0.05em;
    text-decoration: none;
    &:hover {
      background: var(--dark-blue);
      text-decoration: none;
    }
  }
  ${media.laptop`
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    margin: var(--gutter-m);
  `}
`

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const [showButton, setShowButton] = useState(true)

  // const handleCheckout = () => {
  //   window.open(checkout.webUrl, '_self')
  // }

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  let itemCount = 0
  for (const cartItem of lineItems) {
    itemCount = itemCount + cartItem.props.item.quantity
  }

  const sendEmail = e => {
    e.preventDefault()

    setShowButton(false)

    const quoteItems = []
    const date = new Date()

    for (const item of checkout.lineItems) {
      quoteItems.push({
        title: item.title,
        sku: item.variant.sku,
        quantity: item.quantity,
        price: item.variant.price,
        date: date,
        total: checkout.totalPrice,
      })
    }

    const userName = document.getElementById('quote-name').value
    const userEmail = document.getElementById('quote-email').value

    firebase
      .firestore()
      .collection('quotes')
      .add({
        name: userName,
        email: userEmail,
        products: quoteItems,
      })
      .then(ref => {
        // console.log('Added quote with ID: ', ref.id)
      })

    if (checkout.lineItems[0] && !checkout.lineItems[1]) {
      emailjs.send(
        'gmail',
        'template_1de04c3',
        {
          to_name: document.getElementById('quote-name').value,
          product_name: checkout.lineItems[0].title,
          product_sku: checkout.lineItems[0].variant.sku,
          product_quantity: checkout.lineItems[0].quantity,
          product_price: checkout.lineItems[0].variant.price,
          total_price: checkout.totalPrice,
          to_email: document.getElementById('quote-email').value,
          quote_number: Math.floor(100000 + Math.random() * 900000),
        },
        'user_BANVUnROwc7ElTyUBLnJW'
      )
    } else if (
      checkout.lineItems[0] &&
      checkout.lineItems[1] &&
      !checkout.lineItems[2]
    ) {
      emailjs.send(
        'gmail',
        'template_1de04c3',
        {
          to_name: document.getElementById('quote-name').value,
          product_name: checkout.lineItems[0].title,
          product_sku: checkout.lineItems[0].variant.sku,
          product_quantity: checkout.lineItems[0].quantity,
          product_price: checkout.lineItems[0].variant.price,
          product_name2: checkout.lineItems[1].title,
          product_sku2: checkout.lineItems[1].variant.sku,
          product_quantity2: checkout.lineItems[1].quantity,
          product_price2: checkout.lineItems[1].variant.price,
          total_price: checkout.totalPrice,
          to_email: document.getElementById('quote-email').value,
          quote_number: Math.floor(100000 + Math.random() * 900000),
        },
        'user_BANVUnROwc7ElTyUBLnJW'
      )
    } else if (
      checkout.lineItems[0] &&
      checkout.lineItems[1] &&
      checkout.lineItems[2] &&
      !checkout.lineItems[3]
    ) {
      emailjs.send(
        'gmail',
        'template_1de04c3',
        {
          to_name: document.getElementById('quote-name').value,
          product_name: checkout.lineItems[0].title,
          product_sku: checkout.lineItems[0].variant.sku,
          product_quantity: checkout.lineItems[0].quantity,
          product_price: checkout.lineItems[0].variant.price,
          product_name2: checkout.lineItems[1].title,
          product_sku2: checkout.lineItems[1].variant.sku,
          product_quantity2: checkout.lineItems[1].quantity,
          product_price2: checkout.lineItems[1].variant.price,
          product_name3: checkout.lineItems[2].title,
          product_sku3: checkout.lineItems[2].variant.sku,
          product_quantity3: checkout.lineItems[2].quantity,
          product_price3: checkout.lineItems[2].variant.price,
          total_price: checkout.totalPrice,
          to_email: document.getElementById('quote-email').value,
          quote_number: Math.floor(100000 + Math.random() * 900000),
        },
        'user_BANVUnROwc7ElTyUBLnJW'
      )
    } else if (
      checkout.lineItems[0] &&
      checkout.lineItems[1] &&
      checkout.lineItems[2] &&
      checkout.lineItems[3] &&
      !checkout.lineItems[4]
    ) {
      emailjs.send(
        'gmail',
        'template_1de04c3',
        {
          to_name: document.getElementById('quote-name').value,
          product_name: checkout.lineItems[0].title,
          product_sku: checkout.lineItems[0].variant.sku,
          product_quantity: checkout.lineItems[0].quantity,
          product_price: checkout.lineItems[0].variant.price,
          product_name2: checkout.lineItems[1].title,
          product_sku2: checkout.lineItems[1].variant.sku,
          product_quantity2: checkout.lineItems[1].quantity,
          product_price2: checkout.lineItems[1].variant.price,
          product_name3: checkout.lineItems[2].title,
          product_sku3: checkout.lineItems[2].variant.sku,
          product_quantity3: checkout.lineItems[2].quantity,
          product_price3: checkout.lineItems[2].variant.price,
          product_name4: checkout.lineItems[3].title,
          product_sku4: checkout.lineItems[3].variant.sku,
          product_quantity4: checkout.lineItems[3].quantity,
          product_price4: checkout.lineItems[3].variant.price,
          total_price: checkout.totalPrice,
          to_email: document.getElementById('quote-email').value,
          quote_number: Math.floor(100000 + Math.random() * 900000),
        },
        'user_BANVUnROwc7ElTyUBLnJW'
      )
    } else if (
      checkout.lineItems[0] &&
      checkout.lineItems[1] &&
      checkout.lineItems[2] &&
      checkout.lineItems[3] &&
      checkout.lineItems[4]
    ) {
      emailjs.send(
        'gmail',
        'template_1de04c3',
        {
          to_name: document.getElementById('quote-name').value,
          product_name: checkout.lineItems[0].title,
          product_sku: checkout.lineItems[0].variant.sku,
          product_quantity: checkout.lineItems[0].quantity,
          product_price: checkout.lineItems[0].variant.price,
          product_name2: checkout.lineItems[1].title,
          product_sku2: checkout.lineItems[1].variant.sku,
          product_quantity2: checkout.lineItems[1].quantity,
          product_price2: checkout.lineItems[1].variant.price,
          product_name3: checkout.lineItems[2].title,
          product_sku3: checkout.lineItems[2].variant.sku,
          product_quantity3: checkout.lineItems[2].quantity,
          product_price3: checkout.lineItems[2].variant.price,
          product_name4: checkout.lineItems[3].title,
          product_sku4: checkout.lineItems[3].variant.sku,
          product_quantity4: checkout.lineItems[3].quantity,
          product_price4: checkout.lineItems[3].variant.price,
          product_name5: checkout.lineItems[4].title,
          product_sku5: checkout.lineItems[4].variant.sku,
          product_quantity5: checkout.lineItems[4].quantity,
          product_price5: checkout.lineItems[4].variant.price,
          total_price: checkout.totalPrice,
          to_email: document.getElementById('quote-email').value,
          quote_number: Math.floor(100000 + Math.random() * 900000),
        },
        'user_BANVUnROwc7ElTyUBLnJW'
      )
    }

    document.getElementById('quote-form').reset()
  }

  return (
    <>
      <Navigation />
      <CrumbCon>
        <Breadcrumb>
          <Breadcrumb.Item href="/products">
            <p className="blue backArrow">
              &#x25C2;
            </p>
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/products">Continue Shopping</Breadcrumb.Item>
        </Breadcrumb>
      </CrumbCon>
      <HeadingText className="black spaced smaller">
        Shopping Cart ({itemCount})
      </HeadingText>
      <CartCon>
        <div>
          {!isMobile && (
            <CartTable>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Quantitiy</th>
                  <th>Item Total</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>{lineItems}</tbody>
            </CartTable>
          )}
          {isMobile && (
            <div>
              {lineItems} <br />
              <br />
            </div>
          )}
        </div>
        <CartSummary>
          <BodyText className="black bold">Order Summary</BodyText>
          <Divider className="spaced" />
          <div className="total-row">
            <BodyText className="black bold">Subtotal</BodyText>
            {checkout.subtotalPrice && (
              <BodyText className="black bold">
                R{' '}
                {checkout.subtotalPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </BodyText>
            )}
          </div>
          <Divider className="spaced" />
          <div className="total-row">
            <BodyText className="black bold">Estimated Total</BodyText>
            {checkout.totalPrice && (
              <BodyText className="black bold">
                R{' '}
                {checkout.totalPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
              </BodyText>
            )}
          </div>
          <DetailsText className="spaced">
            Taxes and shipping calculated at checkout.
          </DetailsText>
          <BodyText className="black bold">
            Complete the form to receive a quote
          </BodyText>
          <form onSubmit={sendEmail} id="quote-form">
            <FormInput
              className="input full"
              type="text"
              name="name"
              id="quote-name"
              placeholder="* Full Name"
              required
            />
            <FormInput
              className="input full"
              type="email"
              name="email"
              id="quote-email"
              placeholder="* Email"
              required
            />
            {}
            {showButton && (
              <Button
                type="submit"
                className="form-button"
                disabled={checkout.lineItems.length === 0}
              >
                Request Quote
              </Button>
            )}
            {!showButton && (
              <>
                <br />
                <br />
                <BodyText className="black">
                  Your quote has been sent to you, one of our sales agents will
                  contact you soon.
                </BodyText>
              </>
            )}
          </form>
          <Divider className="low spaced" />
          <BodyText className="black bold">Need Help?</BodyText>
          <DetailsText>
            Visit our FAQ page for more information or email our support team on
            +27(0) 12 653 5319. Our operating hours are from Mon - Fri, 8 am - 5
            pm.
          </DetailsText>
        </CartSummary>
      </CartCon>
    </>
  )
}

export default Cart
